import React, { useRef, useEffect, useState, useCallback } from "react";
import clsx from "clsx";
import { Player } from "@lottiefiles/react-lottie-player";

import "style/components/home-lottie-player.scss";

export default function HomeLottiePlayer({
	src,
	className,
	observeMode,
	parentOpacity,
}) {
	const componentRef = useRef();
	const playerRef = useRef();
	const [ready, setReady] = useState(false);

	const onEventHandler = useCallback(
		(event) => {
			if (ready === false) setReady(event === "load");
		},
		[ready]
	);

	useEffect(() => {
		if (!componentRef.current || !ready) return;

		const observer = new IntersectionObserver(([entry]) =>
			playerRef.current[entry.isIntersecting ? "play" : "stop"]()
		);

		observer.observe(componentRef.current);
		return () => observer.disconnect();
	}, [observeMode, ready]);

	useEffect(() => {
		if (!ready) return;
		playerRef.current[parentOpacity >= 0.1 ? "play" : "stop"]();
	}, [parentOpacity, ready]);

	return (
		<div className={clsx("home-lottie-player", className)} ref={componentRef}>
			<Player
				ref={playerRef}
				onEvent={onEventHandler}
				loop
				src={src}
				style={{
					height: "100%",
					width: "100%",
					position: "absolute",
					zIndex: 1,
					top: 0,
					left: 0,
				}}
			/>
		</div>
	);
}
