import React from "react";
import nl2br from "react-nl2br";
import CN from "src/libs/CN";
import useStaticAssetData from "src/hooks/useStaticAssetData";
import LayoutZigZagStack from "src/modules/LayoutZigZagStack";
import HomeSystemFeatureSection from "src/components/HomeSystemFeatureSection";
import { BackgroundMarker } from "src/components/BackgroundColor";
import HomeHalo from "src/components/HomeHalo";
import theHalo from "asset/vectors/the-halo.svg";

const cn = CN("home");

export default function HomeTheHalo({
	label,
	anchor,
	heading,
	intro,
	items,
	footer,
}) {
	const [footerImage] = useStaticAssetData(["halo.png"]);

	return (
		<HomeSystemFeatureSection
			img={theHalo}
			title={label}
			text={heading}
			id={anchor}
			theme={["light", "full"]}>
			<div className={cn("grid-wrap")}>
				<p className={cn("halo-text")}>{nl2br(intro)}</p>
			</div>
			<div className={cn("vertical-spacing")}>
				<HomeHalo contentGrid={items} image={footerImage} />
			</div>
			{/* <div className={cn("vertical-spacing")}>
				<LayoutZigZagStack design="textFirst" rows={footer} />
			</div> */}

			<BackgroundMarker large={true} color="242,241,241" />
		</HomeSystemFeatureSection>
	);
}
