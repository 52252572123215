import React from "react";
import nl2br from "react-nl2br";
import CN from "src/libs/CN";
import HomeSystemFeatureSection from "src/components/HomeSystemFeatureSection";
import { ImgixFluid } from "src/components/Imgix";
import { BackgroundMarker } from "src/components/BackgroundColor";
import Redactor from "src/components/Redactor";
import imagingStation from "asset/vectors/imaging-station-dark.svg";
import ImageTrio from "src/modules/ImageTrio";

const cn = CN("home");

export default function HomeTheSoftware({
	label,
	anchor,
	heading,
	intro,
	items,
	images
}) {
	return (
		<>
			<div className={cn("vertical-spacing")}></div>
			<BackgroundMarker color="242,241,241" />

			<HomeSystemFeatureSection
				img={imagingStation}
				title={label}
				text={heading}
				id={anchor}
				theme={["full"]}>
				<div className={cn("grid-wrap")}>
					<p className={cn("station-text")}>{nl2br(intro)}</p>
				</div>
				<div className={cn("grid-wrap")}>
					{items.map((item, index) => {
						return (
							<Redactor
								html={item.content}
								key={index}
								className={cn("content-grid__item")}
							/>
						);
					})}
				</div>

				<div className={cn("vertical-spacing")}>
					<ImageTrio images={images} />
				</div>

				<BackgroundMarker color="242,241,241" />

			</HomeSystemFeatureSection>
		</>
	);
}
