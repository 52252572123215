import React from "react";
import CN from "src/libs/CN";
import HomeSectionLead from "src/components/HomeSectionLead";

import "style/components/home-system-feature-section.scss";

export default function HomeSystemFeatureSection({
	img,
	title,
	text,
	theme,
	children,
	id,
}) {
	const cn = CN("home-system-feature-section");

	return (
		<div className={cn("bg", ...theme)} id={id}>
			<div className={cn("inner")}>
				<HomeSectionLead img={img} title={title} text={text} theme={theme} />
			</div>
			<div className={cn("inner")}>{children}</div>
		</div>
	);
}
