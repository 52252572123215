import React from "react";
import { graphql } from "gatsby";
import { PageContextProvider } from "src/containers/PageContext";
import Layout from "src/components/Layout";
import SEOStatic from "src/components/SEOStatic";
import transformHomePageModuleData from "src/libs/transformHomePageModuleData";
import BackgroundColor from "src/components/BackgroundColor";

import "style/pages/home.scss";

export default function Home({ pageContext, data }) {
	const { pageMetadata, pageModules } = transformHomePageModuleData(data);

	return (
		<PageContextProvider context={pageContext}>
			<Layout className={"layout--home home"}>
				<SEOStatic
					title={pageMetadata.title}
					description={pageMetadata.description}
				/>
				<BackgroundColor>
					{pageModules &&
						pageModules.map(({ Module, props }, index) => {
							return <Module {...props} key={index} />;
						})}
				</BackgroundColor>
			</Layout>
		</PageContextProvider>
	);
}

export const query = graphql`
	query HomePage($language: String) {
		entry: craftHomePageHomePageEntry(language: { eq: $language }) {
			...HomePageEntry
		}
	}
`;
