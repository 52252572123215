import React, { useState, useEffect, useRef, useMemo } from "react";
import CN from "src/libs/CN";
import {
	clamp,
	relativeProgress as rp,
	easing,
	interpolate,
} from "src/libs/math";
import useStaticAssetData from "src/hooks/useStaticAssetData";
import { usePageContext } from "src/containers/PageContext";
import Link from "src/components/Link";
import { ImgixFluid } from "src/components/Imgix";
import { VideoModalButton, VideoModalContent } from "src/components/VideoModal";
import transformLinkList from "src/libs/transformLinkList";

import "style/modules/home-page-header.scss";

const cn = CN("home-page-header");
const HAS_HERO_CART_ANIMATION = false;

export default function HomePageHeader({
	heading,
	intro,
	primaryCta,
	secondaryCta,
}) {
	const tracking = useRef();
	const sticky = useRef();
	const contentEl = useRef();
	const [prog, setProg] = useState(0);
	const [shift, setShift] = useState(0);
	const [fadeProg, setFadeProg] = useState(1);
	const purple = { r: 115, g: 48, b: 138 };
	const white = { r: 255, g: 255, b: 255 };
	const [modalOpen, setModalOpen] = useState(false);

	const lang = usePageContext("language");
	const cartImages = useStaticAssetData([
		"hero-cart-1.png",
		"hero-cart-2.png",
		"hero-cart-3.png",
	]);

	const classList = useMemo(() => {
		let classList = cn();
		if (HAS_HERO_CART_ANIMATION) {
			classList = `${classList} ${classList}--has-hero-cart-animation`;
		}
		return classList;
	});

	const [videoCta] = primaryCta;
	const [bookCta] = transformLinkList(secondaryCta, lang);

	function scroll() {
		const { top, height } = tracking.current.getBoundingClientRect();
		const { top: st, height: sh } = sticky.current.getBoundingClientRect();
		const { height: ch } = contentEl.current.getBoundingClientRect();
		const { abs } = Math;
		const { innerHeight: wh, innerWidth: ww } = window;
		const p = clamp(abs(top) / (height - wh), 0, 1);
		const shiftVal = Math.min(ch, ww * 0.2);
		const fade = rp((st + sh - wh - shiftVal) / (sh - wh - shiftVal), 0, 0.4);

		setShift(shiftVal);
		setFadeProg(fade);
		setProg(p);
	}

	function interColor(c1, c2, val) {
		const r = interpolate(c1.r, c2.r, val);
		const g = interpolate(c1.g, c2.g, val);
		const b = interpolate(c1.b, c2.b, val);

		return `rgb(${r},${g},${b})`;
	}

	useEffect(() => {
		if (!HAS_HERO_CART_ANIMATION) return;
		window.addEventListener("scroll", scroll);
		window.addEventListener("resize", scroll);
		return () => {
			if (!HAS_HERO_CART_ANIMATION) return;
			window.removeEventListener("resize", scroll);
			window.removeEventListener("scroll", scroll);
		};
	}, [setProg, setFadeProg]);

	const handleOpenModal = () => {
		setModalOpen(true);
	};

	const handleCloseModal = () => {
		setModalOpen(false);
	};

	return (
		<div className={classList} ref={tracking}>
			<div
				className={HAS_HERO_CART_ANIMATION ? cn("sticky") : cn("inner")}
				ref={sticky}
			>
				<div className={cn("content")}>
					<h1
						style={{
							color: HAS_HERO_CART_ANIMATION
								? interColor(purple, white, rp(prog, 0.2, 0.3))
								: purple,
							transition: "color 0.1s",
						}}
					>
						{heading}
					</h1>

					<div
						className={cn("fade")}
						style={
							HAS_HERO_CART_ANIMATION
								? { opacity: rp(prog, 0.15, 0), transition: "opacity 0.1s" }
								: null
						}
						ref={contentEl}
					>
						<div className={cn("grid-wrap")}>
							<p>{intro}</p>
							<div className={cn("interaction")}>
								<VideoModalButton
									className={cn("interaction__watch")}
									buttonText={videoCta.label}
									handleOpenModal={handleOpenModal}
								/>
							</div>
						</div>
					</div>
				</div>

				{HAS_HERO_CART_ANIMATION && (
					<div
						className={cn("images")}
						style={{
							transform: `translate3d(0,${
								-shift * easing.inOutSine(rp(prog, 0.0, 0.2))
							}px,0.1px) `,
							opacity: fadeProg,
							transition: "opacity 0.1s, transform 0.1s",
						}}
					>
						<div className={cn("image")} style={{ opacity: 1 }}>
							<div className={cn("image__outer")}>
								<div className={cn("image__inner")}>
									<ImgixFluid
										{...{
											...cartImages[0],
											sizes: `(min-width: 2200px) 2688px, (min-width: 1680px) 2016px, (min-width: 1280px) 1792px, 140vw`,
										}}
									/>
								</div>
							</div>
						</div>
						<div
							className={cn("image")}
							style={{
								opacity: rp(prog, 0.2, 0.3),
								visibility: rp(prog, 0.2, 0.3) === 0 ? "hidden" : "visible",
								transition: "opacity 0.1s",
								// transform: 'translate3d(0,0,1px)'
							}}
						>
							<div className={cn("image__outer")}>
								<div className={cn("image__inner")}>
									<ImgixFluid
										{...{
											...cartImages[1],
											sizes: `(min-width: 2200px) 2688px, (min-width: 1680px) 2016px, (min-width: 1280px) 1792px, 140vw`,
										}}
									/>
								</div>
							</div>
						</div>
						<div
							className={cn("image")}
							style={{
								opacity: rp(prog, 0.3, 0.4),
								visibility: rp(prog, 0.3, 0.4) === 0 ? "hidden" : "visible",
								transition: "opacity 0.1s",
								// transform: 'translate3d(0,0,1px)'
							}}
						>
							<div className={cn("image__outer")}>
								<div className={cn("image__inner")}>
									<ImgixFluid
										{...{
											...cartImages[2],
											sizes: `(min-width: 2200px) 2688px, (min-width: 1680px) 2016px, (min-width: 1280px) 1792px, 140vw`,
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			<VideoModalContent
				open={modalOpen}
				embedIframe={videoCta.embedIframe}
				handleCloseModal={handleCloseModal}
			/>
		</div>
	);
}
