import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import "style/components/modal.scss";
import { ReactComponent as IconCross } from "asset/vectors/icon-cross.svg";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export default function Modal({ className, children, open, closeModal }) {
	const transitionTime = 200;
	const modalWrapper = useRef();
	const [fade, setFade] = useState(false);
	const [show, setShow] = useState(false);

	useEffect(() => {
		if (open) {
			setTimeout(() => {
				disableBodyScroll(modalWrapper.current);
			}, transitionTime);
			setShow(true);
			setFade(true);
		} else {
			enableBodyScroll(modalWrapper.current);

			setFade(false);

			setTimeout(() => setShow(false), 500);
		}
	}, [open]);

	return (
		<div
			className={clsx(
				"modal",
				{ "modal--open": show },
				{ "modal--fade": fade },
				className
			)}
			style={{ "--transition-time": `${transitionTime}ms` }}>
			<div className="modal__wrapper" ref={modalWrapper}>
				<div className="modal__content">
					<button className="modal__button" onClick={closeModal}>
						<IconCross />
						Close
					</button>
					<div>{children}</div>
				</div>
			</div>
		</div>
	);
}
