import React, { useState } from "react";
import clsx from "clsx";
import Modal from "src/components/Modal";
import { ReactComponent as IconPlay } from "asset/vectors/icon-play.svg";
import Redactor from "src/components/Redactor";

import "style/components/video-modal.scss";

export default function VideoModal({ className, buttonText, embedIframe }) {
	const [open, setOpen] = useState(false);

	const handleOpenModal = () => {
		setOpen(true);
	};

	const handleCloseModal = () => {
		setOpen(false);
	};

	return (
		<div className={clsx("video-modal", className)}>
			<VideoModalButton
				buttonText={buttonText}
				handleOpenModal={handleOpenModal}
			/>
			<VideoModalContent
				open={open}
				embedIframe={embedIframe}
				handleCloseModal={handleCloseModal}
			/>
		</div>
	);
}

export function VideoModalButton({ className, buttonText, handleOpenModal }) {
	return (
		<div
			className={clsx("video-modal__button", className)}
			onClick={handleOpenModal}
			data-cta-click={buttonText}>
			<IconPlay />
			{buttonText}
		</div>
	);
}

export function VideoModalContent({
	className,
	open,
	embedIframe,
	handleCloseModal,
}) {
	return (
		<Modal className={className} open={open} closeModal={handleCloseModal}>
			{embedIframe && (
				<Redactor className="video-modal__inner" html={embedIframe} />
			)}
		</Modal>
	);
}
