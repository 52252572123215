import React, { useState, useEffect, useRef } from "react";
import { relativeProgress as rp, interpolate } from "src/libs/math";

export function BackgroundMarker({ color, large = false }) {
	return (
		<div
			className="bg-marker"
			data-large={large ? "yes" : "no"}
			data-color={`[${color}]`}></div>
	);
}

export default function BackgroundColor({ children }) {
	const [backgroundColor, setBackgroundColor] = useState("white");
	const ref = useRef();

	useEffect(() => {
		const els = Array.from(ref.current.querySelectorAll(".bg-marker"));
		const markersL = [];
		const markersS = [];

		els.forEach((el) => {
			const color = JSON.parse(el.getAttribute("data-color"));
			const large = el.getAttribute("data-large") == "yes";

			const obj = { el, color };

			markersL.push(obj);
			if (!large) markersS.push(obj);
		});

		function getColor(a, b, h) {
			const { top: at } = a.el.getBoundingClientRect();
			const { top: bt } = b.el.getBoundingClientRect();

			if (at > h) return a.color;
			if (bt < h) return false;
			if (at <= h && bt >= h) {
				const prog = rp(h, at, bt);
				const rc = interpolate(a.color[0], b.color[0], prog);
				const gc = interpolate(a.color[1], b.color[1], prog);
				const bc = interpolate(a.color[2], b.color[2], prog);
				return [rc, gc, bc];
			}
		}

		function scroll() {
			const { innerHeight: h, innerWidth: w } = window;
			const hh = h * 0.5;
			const markers = w < 768 ? markersS : markersL;
			const { length } = markers;
			let color = markers[length - 1].color;

			for (let i = 0; i < length - 1; i++) {
				let c = getColor(markers[i], markers[i + 1], hh, w);
				if (c) {
					color = c;
					break;
				}
			}

			setBackgroundColor(`rgb(${color[0]},${color[1]},${color[2]})`);
		}

		window.addEventListener("scroll", scroll);

		return () => {
			window.removeEventListener("scroll", scroll);
		};
	}, []);

	return (
		<>
			<div
				style={{
					width: "100%",
					display: "block",
					position: "fixed",
					height: "100%",
					top: 0,
					left: 0,
					backgroundColor,
					// transform: "translateZ(1px)",
					transition: "background-color 0.2s",
				}}></div>
			<div style={{ width: "100%", position: "relative" }} ref={ref}>
				{children}
			</div>
		</>
	);
}
