import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { clamp, relativeProgress as rp } from "src/libs/math";
import Redactor from "src/components/Redactor";
import { ImgixFluid } from "src/components/Imgix";
import { BackgroundMarker } from "src/components/BackgroundColor";

import "style/components/home-halo.scss";

export default function HomeHalo({ contentGrid, image }) {
	const tracking = useRef();
	const [prog, setProg] = useState(0);

	function cn() {
		const args = [...arguments];
		args[0] = "halo__" + args[0];
		return clsx(...args);
	}

	function scroll() {
		const { top, height } = tracking.current.getBoundingClientRect();
		const { innerHeight: wh } = window;
		const p = 1 - clamp((top + height - wh) / height, 0, 1);

		setProg(p);
	}

	useEffect(() => {
		window.addEventListener("scroll", scroll);
		return () => {
			window.removeEventListener("scroll", scroll);
		};
	}, [setProg]);

	return (
		<>
			<div className={cn("main")} ref={tracking}>
				<div className={cn("content")}>
					{contentGrid.map((item, index) => {
						return (
							<Redactor
								html={item.content}
								key={index}
								className={cn("content__item")}
							/>
						);
					})}
				</div>

				<div className={cn("image-container")}>
					<div
						className={cn("image")}
						style={{ opacity: 0.4 + rp(prog, 0.6, 1) * 0.6 }}>
						<ImgixFluid {...image} />
					</div>

					<BackgroundMarker color="0,0,0" />
					<BackgroundMarker color="242,241,241" />
				</div>
			</div>
		</>
	);
}
