import React from "react";
import CN from "src/libs/CN";
import useStaticAssetData from "src/hooks/useStaticAssetData";
import HomeSystemFeatureSection from "src/components/HomeSystemFeatureSection";
import { ImgixFluid } from "src/components/Imgix";
import ImageTrio from "src/modules/ImageTrio";
import theCart from "asset/vectors/the-cart.svg";

const cn = CN("home");

export default function HomeTheCart({ label, anchor, heading, intro, images }) {
	const [mainImage] = useStaticAssetData("the-cart.jpg");
	return (
		<HomeSystemFeatureSection
			img={theCart}
			title={label}
			text={heading}
			id={anchor}
			theme={["light"]}>
			<div className={cn("grid-wrap")}>
				<ImgixFluid className="home__cart-main-img" {...mainImage} />
			</div>
			<div className={cn("grid-wrap")}>
				<p className={cn("cart-text")}>{intro}</p>
			</div>

			{/* <ImageTrio className={cn("cart-img-trio")} images={images} /> */}
		</HomeSystemFeatureSection>
	);
}
