import { graphql } from "gatsby";
import HomePageHeader from "src/modules/HomePageHeader";
import HomeSystemIntroduction from "src/modules/HomeSystemIntroduction";
import HomeSystemFeaturesIndex from "src/modules/HomeSystemFeaturesIndex";
import HomeTheCart from "src/modules/HomeTheCart";
import HomeTheHalo from "src/modules/HomeTheHalo";
import HomeUserSoftware from "src/modules/HomeUserSoftware";
import HomeImagingStation from "src/modules/HomeImagingStation";
import HomeCaseStudyFeatured from "src/modules/HomeCaseStudyFeatured";
import CartProductHero from "src/modules/CartProductHero";

const PAGE_MODULE_MAP = {
	Craft_homePageModules_systemIntroduction_BlockType: HomeSystemIntroduction,
	Craft_homePageModules_systemTheHalo_BlockType: HomeTheHalo,
	Craft_homePageModules_systemFeaturesIndex_BlockType: HomeSystemFeaturesIndex,
	Craft_homePageModules_systemTheCart_BlockType: HomeTheCart,
	Craft_homePageModules_systemUserSoftware_BlockType: HomeUserSoftware,
	Craft_homePageModules_systemImagingStation_BlockType: HomeImagingStation,
	Craft_homePageModules_caseStudyFeatured_BlockType: HomeCaseStudyFeatured,
	Craft_homePageModules_cartProductHero_BlockType: CartProductHero,
};

export default function transformHomePageModuleData(data) {
	const {
		entry: {
			title,
			description,
			pageHeader: [pageHeader],
			pageModules,
		},
	} = data;

	return {
		pageMetadata: { title, description },
		pageModules: [{ Module: HomePageHeader, props: pageHeader }].concat(
			pageModules
				.map(({ moduleType, ...moduleData }) => {
					const Module = PAGE_MODULE_MAP[moduleType];
					if (!Module) return null;

					return {
						Module,
						props: transformModuleData(moduleType, moduleData),
					};
				})
				.filter(Boolean)
		),
	};
}

let homeFeatures;

export function transformModuleData(moduleType, moduleData) {
	const data = { __typename: null, ...moduleData };

	switch (moduleType) {
		case "Craft_homePageModules_systemFeaturesIndex_BlockType":
			homeFeatures = moduleData.features;
			return data;
		case "Craft_homePageModules_systemTheCart_BlockType":
			return {
				...data,
			};
		case "Craft_homePageModules_systemTheHalo_BlockType":
			return {
				...data,
			};
		case "Craft_homePageModules_systemUserSoftware_BlockType":
			return {
				...data,
			};
		case "Craft_homePageModules_systemImagingStation_BlockType":
			return {
				...data,
			};
		case "Craft_homePageModules_caseStudyFeatured_BlockType":
			return {
				...data,
				item: data.item[0],
			};
		case "Craft_homePageModules_cartProductHero_BlockType":
			return {
				...data
			};
		default:
			return data;
	}
}

export const fragment = graphql`
	fragment HomePageEntry on Craft_homePage_homePage_Entry {
		title
		description: metaDescription
		pageHeader: homePageHeader {
			...HomePageHeader
		}
		pageModules: homePageModules {
			...HomePageModules
		}
	}

	fragment HomePageHeader on Craft_homePageHeader_BlockType {
		heading
		intro
		primaryCta {
			... on Craft_primaryCta_modalVideo_BlockType {
				embedIframe: modalEmbedCode
				label: modalCtaLabel
			}
		}
		secondaryCta {
			... on Craft_secondaryCta_internalLink_BlockType {
				linkEntry {
					uri
					title
				}
				linkLabel
			}
			... on Craft_secondaryCta_externalLink_BlockType {
				linkUrl
				linkLabel
			}
		}
	}

	fragment HomePageModules on Craft_homePageModules_MatrixField {
		moduleType: __typename
		... on Craft_homePageModules_systemIntroduction_BlockType {
			heading
			intro
			items: systemIntroductionRows {
				... on Craft_systemIntroductionRows_BlockType {
					title: rowTitle
					content: text
				}
			}
		}
		... on Craft_homePageModules_systemFeaturesIndex_BlockType {
			heading
			features {
				... on Craft_features_BlockType {
					anchor
					label
				}
			}
		}
		... on Craft_homePageModules_systemTheHalo_BlockType {
			heading
			intro
			items: systemTheHaloItems {
				... on Craft_systemTheHaloItems_BlockType {
					content: text
				}
			}
			footer: systemTheHaloFooter {
				... on Craft_systemTheHaloFooter_BlockType {
					image {
						craftSrc: url
						w: width
						h: height
					}
					content: text
				}
			}
		}
		... on Craft_homePageModules_systemTheCart_BlockType {
			heading
			intro
			images: systemTheCartImages {
				... on Craft_systemTheCartImages_BlockType {
					image {
						craftSrc: url
						w: width
						h: height
					}
					caption
				}
			}
		}
		... on Craft_homePageModules_systemUserSoftware_BlockType {
			items: systemUserSoftwareItems {
				... on Craft_systemUserSoftwareItems_BlockType {
					image {
						craftSrc: url
						w: width
						h: height
					}
					content: text
				}
			}
		}
		... on Craft_homePageModules_systemImagingStation_BlockType {
			heading
			intro
			items: systemImagingStationItems {
				... on Craft_systemImagingStationItems_BlockType {
					content: text
				}
			}
			images: systemImagingStationImages {
				... on Craft_systemImagingStationImages_BlockType {
					image {
						craftSrc: url
						w: width
						h: height
					}
					caption
				}
			}
		}
		... on Craft_homePageModules_caseStudyFeatured_BlockType {
			item: caseStudyFeaturedItem {
				... on Craft_caseStudyFeaturedItem_BlockType {
					image {
						craftSrc: url
						w: width
						h: height
					}
					content: text
					cta {
						... on Craft_cta_internalLink_BlockType {
							linkEntry {
								title
								uri
							}
							linkLabel
						}
						... on Craft_cta_externalLink_BlockType {
							linkUrl
							linkLabel
						}
					}
				}
			}
		}
		... on Craft_homePageModules_cartProductHero_BlockType {
			theme
			newLabel
			heading
			intro
			primaryCta{
			  ... on Craft_primaryCta_BlockType{
				linkLabel
				linkEntry{
					  title
					  uri
					}
				linkUrl
			  }
			}
			list{
			  ... on Craft_list_BlockType{
				text
				icon
			  }
			}
			secondaryCta{
			  ... on Craft_secondaryCta_BlockType{
				linkLabel
				linkEntry{
					  title
					  uri
					}
				linkUrl
			  }
			}
		  }
	}
`;
