import React from "react";
import CN from "src/libs/CN";
import LayoutZigZagStack from "src/modules/LayoutZigZagStack";

const cn = CN("home");

export default function HomeCaseStudyFeatured({ item }) {
	return (
		<div className={cn("vertical-spacing")}>
			<LayoutZigZagStack design="textFirst" rows={[item]} />
		</div>
	);
}
