import React from "react";
import LayoutZigZagStack from "src/modules/LayoutZigZagStack";
import HomeSystemFeatureSection from "src/components/HomeSystemFeatureSection";
import userSoftware from "asset/vectors/user-software.svg";
import CN from "src/libs/CN";

import { BackgroundMarker } from "src/components/BackgroundColor";

const cn = CN("home");

export default function HomeUserSoftware({ label, anchor, items }) {
	return (
		<>
			{/* <BackgroundMarker large={true} color="115,48,138" /> */}

			<HomeSystemFeatureSection
				img={userSoftware}
				title={label}
				id={anchor}
				theme={["full"]}>
				<div className={cn("vertical-spacing")}>
					<LayoutZigZagStack
						// className={cn("the-software-grid")}
						design="textFirst"
						rows={items}
					/>
				</div>

				{/* <BackgroundMarker large={true} color="115,48,138" /> */}
			</HomeSystemFeatureSection>
		</>
	);
}
