import clsx from "clsx";
export default function CN(base) {
	return function () {
		const args = [...arguments];

		if (args.length === 0) return base;

		const first = args[0];
		args.forEach((arg, i) => {
			args[i] = base + "__" + first;
			if (i > 0) args[i] += "--" + arg;
		});
		return clsx(...args);
	};
}
