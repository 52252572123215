import { useStaticQuery, graphql } from "gatsby";
import { usePageContext } from "src/containers/PageContext";

export default function useStaticAssetData(filenames = []) {
	const items = [];
	useStaticAssetVolume()
		.filter((asset) => {
			return filenames.indexOf(asset.filename) >= 0;
		})
		.map((asset) => {
			return {
				...asset,
				language: null,
			};
		})
		.forEach((item) => (items[filenames.indexOf(item.filename)] = item));

	return items;
}

export function useStaticAssetVolume() {
	const {
		craft: { assets },
	} = useStaticQuery(query);

	const lang = usePageContext("language") || FALLBACK_LNG;
	return assets.filter((asset) => lang === asset.language);
}

const query = graphql`
	query {
		craft: allCraftStaticAsset(filter: { enabled: { eq: true } }) {
			assets: nodes {
				language
				filename
				craftSrc: url
				w: width
				h: height
			}
		}
	}
`;
