import React from "react";
import CN from "src/libs/CN";
import { BackgroundMarker } from "src/components/BackgroundColor";
import Redactor from "src/components/Redactor";
import Link from "src/components/Link";
import { ReactComponent as TheCart } from "asset/vectors/the-cart.svg";
import { ReactComponent as TheHalo } from "asset/vectors/the-halo.svg";
import { ReactComponent as UserSoftware } from "asset/vectors/user-software.svg";
import { ReactComponent as ImagingStation } from "asset/vectors/imaging-station.svg";

import "style/modules/home-system-features-index.scss";

export default function HomeSystemFeaturesIndex({ heading, features }) {
	const cn = CN("home-system-features-index");
	const svgs = [TheCart, TheHalo, UserSoftware, ImagingStation];

	// TODO: Properly disable component
	return (<div></div>);
	// return (
	// 	<div className={cn()}>
	// 		<div className={cn("heading")}>
	// 			<Redactor html={heading} />
	// 			<BackgroundMarker color="0,0,0" />
	// 		</div>
	// 		<div className={cn("icons")}>
	// 			<div className={cn("icons__grid-pos")}>
	// 				{features.map(({ anchor, label }, index) => {
	// 					const SVG = svgs[index];
	// 					return (
	// 						<div className={cn("icon")} key={index}>
	// 							<Link to={anchor}>
	// 								<SVG />
	// 								<span>{label}</span>
	// 							</Link>
	// 						</div>
	// 					);
	// 				})}
	// 			</div>
	// 		</div>
	// 	</div>
	// );
}
