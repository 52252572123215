import React, { useState, useEffect, useRef, Fragment } from "react";
import useStaticAssetData from "src/hooks/useStaticAssetData";
import { clamp, relativeProgress as rp, easing } from "src/libs/math";
import CN from "src/libs/CN";
import { BackgroundMarker } from "src/components/BackgroundColor";
import Redactor from "src/components/Redactor";
import HomeLottiePlayer from "src/components/HomeLottiePlayer";

import "style/modules/home-system-introduction.scss";

const cn = CN("home-system-introduction");

export default function HomeSystemIntroduction({ heading, intro, items }) {
	const tracking = useRef();
	const [prog, setProg] = useState([]);
	const [active, setActive] = useState(true);
	const { abs, max, min } = Math;

	// let lottieSrcs = useStaticAssetData([
	// 	"1-setup.json",
	// 	"2-imaging-product.json",
	// 	"3-shelf.json",
	// 	"4-pairing.json",
	// 	"5-takes-product.json",
	// 	"6-walks-out.json",
	// ]).map(
	// 	({ craftSrc }) =>
	// 		`//${IMGIX_QUALIFIED_URL}${craftSrc.replace(CRAFT_URL, "")}`
	// );

	const lottieSrcs = [
		require("asset/lotties/1-setup.json"),
		require("asset/lotties/2-imaging-product.json"),
		require("asset/lotties/3-shelf.json"),
		require("asset/lotties/4-pairing.json"),
		require("asset/lotties/5-takes-product.json"),
		require("asset/lotties/6-walks-out.json"),
	];

	return (
		<div className={cn()}>
			<div className={cn("grid-wrap")}>
				<div className={cn("header")}>
					{heading && (
						<Redactor className={cn("heading")} tagName="h2" html={heading} />
					)}
					{intro && <h3 className={cn("intro")}>{intro}</h3>}
				</div>
			</div>

			<div className={cn("main")} ref={tracking}>
				<div style={{ position: "absolute", bottom: "0" }}>
					{/* <BackgroundMarker color="242,241,241" /> */}
				</div>

				<div className={cn("grid")}>
					{items.map((value, index) => {
						const lottieSrc = lottieSrcs[index];

						return (
							<div className={cn("grid-item")} key={index}>
								<div className={cn("image")}>
									<div className={cn("text__grid-wrap")}>
										<div
											className={cn(
												"image__grid-position",
												index < 3 ? "right" : "left"
											)}
											data-index={index}
										>
											<HomeLottiePlayer src={lottieSrc} />
										</div>
									</div>
								</div>
								<div className={cn("text")}>
									<div className={cn("text__grid-wrap")}>
										<div
											className={cn(
												"text__grid-position",
												index < 3 ? "left" : "right"
											)}
										>
											<div className="redactor">
												<h5>{value.title}</h5>
												<Redactor html={value.content} tagName="p" />
											</div>
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}
