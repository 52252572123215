import React from "react";
import CN from "src/libs/CN";
import { ImgixFluid } from "src/components/Imgix";
import Link from "src/components/Link";
import transformLinkList from "src/libs/transformLinkList";
import { usePageContext } from "src/containers/PageContext";
import useStaticAssetData from "src/hooks/useStaticAssetData";

import { ReactComponent as IconArrowRightPurpleBg } from "asset/vectors/arrow-right-purple-bg.svg";

import { ReactComponent as IconBatteryBlack } from "asset/vectors/icon-battery-black.svg";
import { ReactComponent as IconBatteryWhite } from "asset/vectors/icon-battery-white.svg";
import { ReactComponent as IconDataBlack } from "asset/vectors/icon-data-black.svg";
import { ReactComponent as IconDataWhite } from "asset/vectors/icon-data-white.svg";
import { ReactComponent as IconModularBlack } from "asset/vectors/icon-modular-black.svg";
import { ReactComponent as IconModularWhite } from "asset/vectors/icon-modular-white.svg";
import { ReactComponent as IconUpgradeBlack } from "asset/vectors/icon-upgrade-black.svg";
import { ReactComponent as IconUpgradeWhite } from "asset/vectors/icon-upgrade-white.svg";
import { ReactComponent as IconFastBlack } from "asset/vectors/icon-fast-black.svg";
import { ReactComponent as IconFastWhite } from "asset/vectors/icon-fast-white.svg";

import "style/components/cart-product-hero.scss";

export default function CartProductHero({
	theme,
	newLabel,
	heading,
	intro,
	primaryCta,
	list,
	secondaryCta
}) {
	const cn = CN("cart-product-hero");
	const language = usePageContext("language");
	const [learnMoreCta] = primaryCta ? transformLinkList(primaryCta, language) : [{}];
	const [bookNowCta] = transformLinkList(secondaryCta, language);
	const isHomePage = usePageContext("pageType") !== "cartPage";

	const listIcons = {
		battery: theme === "light" ? IconBatteryBlack : IconBatteryWhite,
		data: theme === "light" ? IconDataBlack : IconDataWhite,
		modular: theme === "light" ? IconModularBlack : IconModularWhite,
		upgrade: theme === "light" ? IconUpgradeBlack : IconUpgradeWhite,
		fast: theme === "light" ? IconFastBlack : IconFastWhite,
	}

	const [cartImage] = theme === "light" ? useStaticAssetData("modcart-hero.png") : useStaticAssetData("smartcart-hero.png");
	let containerClassName = `${cn()} cart-product-hero--${theme} ${isHomePage ? "cart-product-hero--margin-bottom" : ""}`
	return (
		<div className={containerClassName}>
			<div className={cn(theme)}>
				<div className={cn("inner")}>
					<div className={cn("text", theme)}>
						{newLabel ? (
							<div className={cn("new-label")}>
								{newLabel}
							</div>
						) : null}
						<div className={cn("heading")}>
							<p>
								{heading}
							</p>
						</div>
						<div className={cn("intro")}>
							<p>{intro}</p>
						</div>
						{isHomePage && learnMoreCta?.url ? 
							<div className={cn("primary-cta")}>
								<div className={cn("arrow-icon")}>
									<IconArrowRightPurpleBg />		
								</div>
								<Link
									to={learnMoreCta?.url}
									data-cta-click={learnMoreCta?.label}>
									{learnMoreCta?.label}
								</Link>
							</div>
						: null}

						{ list?.length ?
							<ul className={cn("list")}>
								{list?.map((item, i) => {
									const Icon = listIcons[item.icon];
									
									return(
										<li className={cn("list-item")} key={i} >
											<span className={cn("list-item-icon")}>{Icon ? <Icon /> : null} </span>
											{item?.text}
										</li>
									)
								})}
							</ul>
						: null} 
					</div>
					<div className={cn("image")}>
						<ImgixFluid {...cartImage}/>
					</div>
					{bookNowCta?.url ? 
						<div className={cn("secondary-cta", theme)}>								
							<Link
							to={bookNowCta?.url}
							data-cta-click={bookNowCta?.label}>
							{bookNowCta?.label}
							</Link>
						</div>
					: null}
				</div>
			</div>
		</div>
	);
}
