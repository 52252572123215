import React from "react";
import CN from "src/libs/CN";
import Redactor from "src/components/Redactor";

import "style/components/home-section-lead.scss";

export default function HomeSectionLead({ text, theme = [""] }) {
	const cn = CN("home-section-lead");

	return (
		<div className={cn()}>
			<div className={cn("inner", ...theme)}>
				{text && (
					<Redactor html={text} className={cn("heading")} tagName="h2" />
				)}
			</div>
		</div>
	);
}
